import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-城市仓管理-修改提现状态
 * @param {*} params
 * @returns
 */
export function postCityStoreWithdrawalStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/withdrawalStatus`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-城市仓商品补充
 * @param {*} params
 * @returns
 */
export function postAddProductTdc(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/add/product_tdc`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-查看自提点-变更记录
 * @param {*} params
 * @returns
 */
export function getDeliveryChangeRecord(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/delivery/log`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-迁移用户
 * @param {*} params
 * @returns
 */
export function postCityStoreMoveUser(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/move/member`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-关仓
 * @param {*} params
 * @returns
 */
export function postCityStoreClose(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/close`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-查看自提点-永久关闭
 * @param {*} params
 * @returns
 */
export function postCityStoreDeliveryClose(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/delivery/close`,
    data: params,
  });
}

/**
 * 总控-城市仓入驻-下拉选项
 * @param {*} params
 * @returns
 */
export function postTdcJoiSelectItem(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/join/select/item`,
    data: params,
  });
}

/**
 * 总控-城市仓入驻-省市区选项
 * @param {*} params
 * @returns
 */
export function postTdcJoiSelectArea(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/join/select/area`,
    data: params,
  });
}

/**
 * 总控-城市仓入驻-审核列表
 * @param {*} params
 * @returns
 */
export function postTdcJoinList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/join/list`,
    data: params,
  });
}

/**
 * 总控-城市仓入驻-审核操作
 * @param {*} params
 * @returns
 */
export function postTdcJoinAudit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/tdc/join/audit`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 -城市仓省市区列表
 * @param {*} params
 * @returns
 */
export function postLogisticsAreas(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/areas`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 -禁售品类
 * @param {*} params
 * @returns
 */
export function postLogisticsForbidCategories(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/forbid/categories`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 - 签约状态变更
 * @param {*} params
 * @returns
 */
export function postLogisticsContractStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/contract/status`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 - 提报设置
 * @param {*} params
 * @returns
 */
export function postLogisticsDepartReport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/depart/report`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 - 禁售品类保存
 * @param {*} params
 * @returns
 */
export function postLogisticsForbidCategoriesSave(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/forbid/categories/save`,
    data: params,
  });
}

/**
 * 总控-城市仓管理 - 设置地采专区
 * @param {*} params
 * @returns
 */
export function postLogisticsLocalRegion(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/local/region`,
    data: params,
  });
}
